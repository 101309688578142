import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { Link, BrowserRouter, Route, Routes } from 'react-router-dom';
import {ToastContainer, toast} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

import cardgif from "./images/card.gif"
import tg from "./images/tg.webp"
import tw from "./images/tw.webp"
import web from "./images/web.webp"
import logo from "./images/logo.webp"

import nftabi from "./abi/nft.json"
import tokenabi from "./abi/token.json"
import marketplaceabi from "./abi/marketplace.json"
const nftAddress = "0x838E37f209950540bBD54D4f52c693a968D79cF8"
const tokenAddress = "0x55d398326f99059fF775485246999027B3197955"
const marketplaceAddress = "0xF51576b5B5C7D93366C7D5DDfdC89Bd66195d61c"
const rpcProvider = "https://bsc-dataseed4.defibit.io"


let userAccount = ""

function App() {
  let [cwText, setCwText] = useState("Connect Wallet")
  return (
    <div className="App">
      <BrowserRouter>
      <Header cwText={cwText} setCwText = {setCwText} />
      <ToastContainer 
      position='top-center'
      autoClose={1000}
      theme="dark"
      newestOnTop={false}
      />
      <Routes>
        <Route path='/' element={<MintPage/>}/>
        <Route path='/marketplace' element={<Marketplace/>}/>
        <Route path='/inventory' element={<Inventory cwText={cwText}/>}/>
      </Routes>
    </BrowserRouter>
    </div>
  );
}

function Header(props){
  let [isMenuOpen, setIsMenuOpen] = useState(false)
  
  async function connect(){
    if(typeof window.ethereum === 'undefined'){
      toast.error("Please install MetaMask or use MetaMask browser!")
    }else{
      let provider = new ethers.BrowserProvider(window.ethereum)
      let signer = await provider.getSigner()
      .then(res => {
        if(props.cwText === "Connect Wallet") {
          toast.success("Connected with 0x..."+res.address.slice(38,42))
        }
        userAccount = res.address
        props.setCwText("0x..."+res.address.slice(38,42))
        window.ethereum.on("accountsChanged", async () => {
          res = await provider.getSigner()
          userAccount = res.address
          props.setCwText("0x..."+res.address.slice(38,42))
        })
      })
      .catch((ex) => {
        toast.error(ex.message)})
    }
  }

  async function handleMenu(){
    if(isMenuOpen){
      document.getElementById("MobileMenu").style.setProperty("display","none")
    }else{
      document.getElementById("MobileMenu").style.setProperty("display","grid")
    }
    setIsMenuOpen(!isMenuOpen)
  }
  return(
    <div id='Header'>
      <img src={logo} alt="logo"/>
      <div id='Menu'>
        <Link to="/">Mint</Link>
        <Link to="/marketplace">Marketplace</Link>
        <Link to="/inventory">Inventory</Link>
      </div>
      <div id='HeaderRight'>
        <button id='ConnectButton' onClick={connect}>{props.cwText}</button>
        <a href="https://t.me/KingsFund"><img src={tg} alt="tg" /></a>
        <a href="https://twitter.com/KingFundFinance"><img src={tw} alt="tw" /></a>
        <a href="http://www.kingsfund.io/"><img src={web} alt="web" /></a>
      </div>
      <div id='MobileMenuRight'>
        <button id='ConnectButton' onClick={connect}>{props.cwText}</button>
        <button onClick={e=>handleMenu()}>
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 50 50">
          <path d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 Z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 Z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 Z"></path>
          </svg>
        </button>
      </div>
      <div id='MobileMenu'>
        <button onClick={e=>handleMenu()}>X</button>
        <div id='MobileMenuItems'>
          <Link to="/" onClick={e=>handleMenu()}>Mint</Link>
          <Link to="/marketplace" onClick={e=>handleMenu()}>Marketplace</Link>
          <Link to="/inventory" onClick={e=>handleMenu()}>Inventory</Link>
        </div>
        <div id='MobileMenuSocials'>
          <a href="https://t.me/KingsFund"><img src={tg} alt="tg" /></a>
          <a href="https://twitter.com/KingFundFinance"><img src={tw} alt="tw" /></a>
          <a href="http://www.kingsfund.io/"><img src={web} alt="web" /></a>
        </div>
      </div>
    </div>
  )
}

function MintPage(){
  useEffect(() => {
    getMintInfo()
  },[])
  let [mintAmount, setMintAmount] = useState(0)
  let [isMinting, setIsMinting] = useState(false)

  const [totalMinted, setTotalMinted] = useState(0)
  async function getMintInfo(){
    let provider = new ethers.JsonRpcProvider(rpcProvider)
    let mintingContract = new ethers.Contract(nftAddress, nftabi, provider)
    const totalMintedBN = await mintingContract.totalSupply()
    setTotalMinted(ethers.formatUnits(totalMintedBN,0))
  }
  
  async function mint(amount){
    let provider = new ethers.BrowserProvider(window.ethereum)
    let signer = await provider.getSigner()
    const tokenContract = new ethers.Contract(tokenAddress,tokenabi,signer)
    const mintingContract = new ethers.Contract(nftAddress,nftabi,signer)
    const allowanceBN = await tokenContract.allowance(signer.address,nftAddress)
    const allowance = Number(ethers.formatUnits(allowanceBN, 18))
    setIsMinting(true)
    //approve
    const approving = toast.loading(`Approving...`)
    const approve = await tokenContract.approve(nftAddress,ethers.parseEther(`${amount*500}`,18))
    .then(async res => {
      await res.wait()
      toast.update(approving, {render:"Approved Successfully", type:"success", autoClose:2000, isLoading:false})
      //mint
      const minting = toast.loading(`Minting ${amount} NFT(s)...`)
      const mint = await mintingContract.safeMint(userAccount, amount)
      .then(async result => {
        await result.wait()
        toast.update(minting, {render:"Minted Successfully", type:"success", autoClose:2000, isLoading:false})
      })
      .catch(ex =>{
        toast.update(minting, {render:ex.code, type:"error", autoClose:2000, isLoading:false})
      })
    })
    .catch(err => {
      toast.update(approving, {render:err.code, type:"error", autoClose:2000, isLoading:false})
      return
    })
    getMintInfo()
    setIsMinting(false)
  }

  function handleAmount(num){
    if(mintAmount === 0 && num < 0) return
    if(mintAmount === 20 && num > 0) return
    setMintAmount(mintAmount + num)
  }
  return(
    <div id='MintPage'>
      <div id='MintCard'>
        <span>Collection Of KingsFund NFTs</span>
        <img id='CardGif' src={cardgif} alt="cardgif" />
        <span style={{color: "#ffde73", fontSize: "20px"}}> {totalMinted} </span>
        <div id='MintAmountInput'>
          <button onClick={e => handleAmount(-1)}>-</button>
          <span> {mintAmount} </span>
          <button onClick={e => handleAmount(1)}>+</button>
        </div>
        <div id='CardTotal'>
          <span>Total</span>
          <span> {mintAmount * 500} USDT</span>
        </div>
        <button onClick={e=>mint(mintAmount)} disabled={mintAmount === 0 || userAccount === "" || isMinting}>Mint</button>
      </div>
    </div>
  )
}

function Marketplace(){
  useEffect(() => {
    getMarketplaceItems()
  },[])
  let [marketplaceItems, setMarketplaceItems] = useState([])
  async function getMarketplaceItems(){
    let provider = new ethers.JsonRpcProvider(rpcProvider)
    let marketplaceContract = new ethers.Contract(marketplaceAddress, marketplaceabi, provider)
    const fetchMarketItems = await marketplaceContract.fetchListedNfts()
    setMarketplaceItems(fetchMarketItems)
  }
  async function buyNft(item){
    let provider = new ethers.BrowserProvider(window.ethereum)
    let signer = await provider.getSigner()
    const tokenContract = new ethers.Contract(tokenAddress,tokenabi,signer)
    const marketplaceContract = new ethers.Contract(marketplaceAddress,marketplaceabi,signer)
    //approve
    const approving = toast.loading(`Approving...`)
    const approve = await tokenContract.approve(marketplaceAddress,ethers.parseEther("500",18))
    .then(async res => {
      await res.wait()
      toast.update(approving, {render:"Approved Successfully", type:"success", autoClose:2000, isLoading:false})
      //mint
      const minting = toast.loading(`Buying #${item}...`)
      const buy = await marketplaceContract.buyNft(item)
      .then(async result => {
        await result.wait()
        toast.update(minting, {render:"Bought Successfully", type:"success", autoClose:2000, isLoading:false})
      })
      .catch(ex =>{
        toast.update(minting, {render:ex.code, type:"error", autoClose:2000, isLoading:false})
      })
    })
    .catch(err => {
      toast.update(approving, {render:err.code, type:"error", autoClose:2000, isLoading:false})
      return
    })
    getMarketplaceItems()
  }
  return(
    <div id='Marketplace'>
      <h2 style={{color: "#ffde73"}}>Live NFTs For Sale</h2>
      <hr className='Seperator'/>
      <div id='MarketplaceContainer'>
        {
          marketplaceItems.map((value,index) =>{
            return(
              <div id='MarketplaceCard' key={index}>
                <span style={{color: "#ffde73"}}>KingsFund NFT #{ethers.formatUnits(value,0)}</span>
                <span>Owning a KingsFund NFT will bring you into the KingsFund, granting access to all gains. One investment for many returns.</span>
                <hr className='Seperator'/>
                <img src={cardgif} alt="cardgifinv" />
                <hr className='Seperator'/>
                <div id='CardTotal'>
                  <span>NFT Price</span>
                  <span>500 USDT (Royalty Fee 5%)</span>
                </div>
                <button style={{width:"100%"}} onClick={e => buyNft(ethers.formatUnits(value,0))} disabled={userAccount === ""}>Buy</button>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

function Inventory(props){
  let [userInventory, setUserInventory] = useState([])
  let [userForSale, setUserForSale] = useState([])
  let [viewSwitch, setViewSwitch] = useState(true)
  
  useEffect(() => {
    setVisibility(viewSwitch)
    if(userAccount !== "") getInventoryItems()
  },[props.cwText])

  async function getInventoryItems(){
    let provider = new ethers.JsonRpcProvider(rpcProvider)
    let nftContract = new ethers.Contract(nftAddress, nftabi, provider)
    const marketplaceContract = new ethers.Contract(marketplaceAddress,marketplaceabi,provider)
    const fetchInventory = await nftContract.getUserNfts(userAccount)
    const fetchUserSale = await marketplaceContract.fetchUserListedNfts(userAccount)
    setUserInventory(fetchInventory)
    setUserForSale(fetchUserSale)
    console.log(fetchUserSale)
  }

  async function listItem(item){
    let provider = new ethers.BrowserProvider(window.ethereum)
    let signer = await provider.getSigner()
    const nftContract = new ethers.Contract(nftAddress,nftabi,signer)
    const marketplaceContract = new ethers.Contract(marketplaceAddress,marketplaceabi,signer)
    const approving = toast.loading(`Approving...`)
    const approve = await nftContract.approve(marketplaceAddress,item)
    .then(async res => {
      await res.wait()
      toast.update(approving, {render:"Approved Successfully", type:"success", autoClose:2000, isLoading:false})
      //mint
      const minting = toast.loading(`Listing item #${item}...`)
      const mint = await marketplaceContract.listNft(item)
      .then(async result => {
        await result.wait()
        toast.update(minting, {render:"Listed Successfully", type:"success", autoClose:2000, isLoading:false})
      })
      .catch(ex =>{
        toast.update(minting, {render:ex.code, type:"error", autoClose:2000, isLoading:false})
      })
    })
    .catch(err => {
      toast.update(approving, {render:err.code, type:"error", autoClose:2000, isLoading:false})
      return
    })
    getInventoryItems()
  }

  async function delistItem(item){
    let provider = new ethers.BrowserProvider(window.ethereum)
    let signer = await provider.getSigner()
    const marketplaceContract = new ethers.Contract(marketplaceAddress,marketplaceabi,signer)
    const minting = toast.loading(`Delisting item #${item}...`)
      const delist = await marketplaceContract.delistNft(item)
      .then(async result => {
        await result.wait()
        toast.update(minting, {render:"Deisted Successfully", type:"success", autoClose:2000, isLoading:false})
      })
      .catch(ex =>{
        toast.update(minting, {render:ex.code, type:"error", autoClose:2000, isLoading:false})
      })
      getInventoryItems()
  }

  async function setVisibility(bool){
    if(bool){
      document.getElementById("MyButton").style.setProperty("background", "#ffde73")
      document.getElementById("SaleButton").style.setProperty("background", "none")
    }else{
      document.getElementById("MyButton").style.setProperty("background", "none")
      document.getElementById("SaleButton").style.setProperty("background", "#ffde73")
    }
    setViewSwitch(bool)
  }

  return(
    <div id='Inventory'>
      <div id='InventoryButtons'>
        <button id='MyButton' onClick={e=>setVisibility(true)}>My NFTs</button>
        <button id='SaleButton' onClick={e=>setVisibility(false)}>My NFTs On Sale</button>
      </div>
      
      <div id='InventoryContainer'>
        {
          viewSwitch ? 

          userInventory.map((value,index) => {
            return(
              <div id='UserInventoryCard' key={index}>
                <span style={{color: "#ffde73"}}>KingsFund NFT #{ethers.formatUnits(value,0)}</span>
                <span>Owning a KingsFund NFT will bring you into the KingsFund, granting access to all gains. One investment for many returns.</span>
                <hr className='Seperator'/>
                <img src={cardgif} alt="cardgifinv" />
                <hr className='Seperator'/>
                <button onClick={e=>listItem(ethers.formatUnits(value,0))}>List on Marketplace</button>
              </div>
            )
          })
          
          : 
          
          userForSale.map((value,index) => {
            return(
              <div id='UserInventoryCard' key={index}>
                <span style={{color: "#ffde73"}}>KingsFund NFT #{ethers.formatUnits(value,0)}</span>
                <span>Owning a KingsFund NFT will bring you into the KingsFund, granting access to all gains. One investment for many returns.</span>
                <hr className='Seperator'/>
                <img src={cardgif} alt="cardgifinv" />
                <hr className='Seperator'/>
                <button onClick={e=>delistItem(ethers.formatUnits(value,0))}>Remove Listing</button>
              </div>
            )
          })
        }
      </div>
      
    </div>
  )
}

export default App;
